import React, { PureComponent } from "react";
import {
  Home,
  Facebook,
  Mail,
  Twitter,
  Linkedin,
  Instagram,
} from "react-feather";
import WhatsappIcon from "mdi-react/WhatsappIcon";
import "../css/style.css";
import "../css/custom.css";
import "../css/preloader.css";
import "../css/bootstrap.min.css";
import AOS from "aos";
import Server from "../comp/server";
import Qstn from "../comp/qstn";
import Footer from "../comp/footer";

export default class Not_Found extends PureComponent {
  constructor(props, context) {
    super(props, context);
    AOS.init();
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }

  render() {
    return (
      <main className="main-container">
        <div className="back-to-home rounded d-none d-sm-block">
          <a href="index.html" className="btn btn-icon btn-soft-primary">
            <Home />
          </a>
        </div>
        <section
          className="grey-shape-bg d-flex section align-items-center"
          id="home"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-7">
                <div
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  className="title-heading mt-4"
                >
                  <div className="text-uppercase display-3">😔h! No</div>
                  <div className="text-capitalize text-dark mb-4 error-page">
                    This link is little-unknown
                  </div>
                  <p className="text-muted-header para-desc mx-auto">
                    This Certificate link is broken. We could not find
                    Certificate linked to this link on our Datebases.
                  </p>
                  <p className="text-muted-header para-desc mx-auto text-blue">
                    If you are here to verify the certificate please contact the
                    link sender.
                  </p>
                  <p className="text-muted-header para-desc mx-auto">
                    If the certificate at this link was issued to you try
                    contacting Certificate Issuer/Event Organizer.
                  </p>
                  {Server.Server.includes("littlecuberssolution") ? (
                    ""
                  ) : (
                    <div className>
                      <a
                        href="https://api.whatsapp.com/send?phone=918595119451&text=I tried to visit the certificate at this link {} but it was not found. Please Help me. My Email id is : "
                        className="btn btn-outline-primary rounded"
                      >
                        Request Instant Help
                      </a>
                    </div>
                  )}
                  <div className="mt-2">
                    <a
                      href="/find-certificates"
                      className="btn btn-outline-primary rounded"
                    >
                      Find certificates using email
                    </a>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div
                data-aos="fade-left"
                data-aos-duration={1000}
                className="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0"
              >
                <div className="position-relative">
                  <div className>
                    <img
                      className="rounded-lg"
                      src="../../assets/img/cert-error2.svg"
                      alt="Certificate 1"
                    />
                  </div>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>

        {Server.Server.includes("littlecuberssolution") ? "" : <Qstn />}

        <div class="mt-5"></div>
        {Server.Server.includes("littlecuberssolution") ? "" : <Footer />}
      </main>
    );
  }
}
