import "../../../node_modules/aos/dist/aos.css";
import React, { PureComponent } from "react";
import {
  ArrowUp,
  Award,
  Briefcase,
  CheckCircle,
  Crosshair,
  Globe,
  Users,
} from "react-feather";
import WhatsappIcon from "mdi-react/WhatsappIcon";
import CertificateIcon from "mdi-react/CertificateIcon";
import QrcodeScanIcon from "mdi-react/QrcodeScanIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import ShareIcon from "mdi-react/ShareIcon";
import HospitalBuildingIcon from "mdi-react/HospitalBuildingIcon";
import StoreIcon from "mdi-react/StoreIcon";
import RocketIcon from "mdi-react/RocketIcon";
import CrosshairsGpsIcon from "mdi-react/CrosshairsGpsIcon";
import "../css/style.css";
import "../css/custom.css";
import "../css/preloader.css";
import "../css/bootstrap.min.css";
import Header from "../comp/header";
import Server from "../comp/server";
import Footer from "../comp/footer";
import Qstn from "../comp/qstn";
import Typed from "react-typed";
import AOS from "aos";
import $ from "jquery";

export default class Home extends PureComponent {
  constructor(props, context) {
    super(props, context);
    AOS.init();
  }

  componentDidMount() {
    // Header sticky
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".sticky").addClass("nav-sticky");
      } else {
        $(".sticky").removeClass("nav-sticky");
      }
    });

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $(".back-to-top").fadeIn();
      } else {
        $(".back-to-top").fadeOut();
      }
    });
    $(".back-to-top").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 3000);
      return false;
    });

    // Nav Bar toggle
    $(".navbar-toggle").on("click", function (event) {
      $(this).toggleClass("open");
      $("#navigation").slideToggle(400);
    });

    $(".navigation-menu>li").slice(-1).addClass("last-elements");

    $(".menu-arrow,.submenu-arrow").on("click", function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this)
          .parent("li")
          .toggleClass("open")
          .find(".submenu:first")
          .toggleClass("open");
      }
    });

    $(".navigation-menu a").each(function () {
      if (this.href == window.location.href) {
        $(this).parent().addClass("active");
        $(this).parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("active");
      }
    });
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }
  render() {
    return (
      <main className="main-container">
        <Header />
        <section class="section border-bottom d-table w-100" id="home">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-7">
                <div
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  class="title-heading mt-4"
                >
                  <div
                    class="alert alert-light alert-pills form-shadow mt-3 mt-sm-0"
                    role="alert"
                  >
                    <span class="badge badge-pill badge-danger">v1.1</span>{" "}
                    <span class="content">
                      Create <span class="text-blue">Certificates</span> and
                      Publish with QR-Code
                    </span>
                  </div>
                  <h1 class="heading mb-3">
                    Online Digital Certificate Maker & Management System For{" "}
                    <Typed
                      strings={[
                        "Business",
                        "Startups",
                        "Events",
                        "Schools",
                        "Colleges",
                        "NGOs",
                        "Institutes",
                      ]}
                      typeSpeed={90}
                      backSpeed={90}
                      loop
                    >
                      <span className="text-blue" />
                    </Typed>
                  </h1>
                  <p class="para-desc text-muted">
                    Free Online Digital Certificate system to make, manage and
                    publish online certificates of Participation, appreciation,
                    achievement, internship with few clicks.
                  </p>
                  <div class="mt-4">
                    <a
                      href={Server.Client + "/signup"}
                      target="_blank"
                      rel="noopener noreferrer"
                      class="btn btn-outline-primary "
                    >
                      Get Started for Free
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-5 mt-4 mt-sm-0">
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        class="form-rounded-lg w-100"
                        src="../../assets/img/certifidoc_imgs/1.jpg"
                        alt="Certificate 1"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="form-rounded-lg w-100"
                        src="../../assets/img/certifidoc_imgs/2.jpg"
                        alt="Certificate 2"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="form-rounded-lg w-100"
                        src="../../assets/img/certifidoc_imgs/3.jpg"
                        alt="Certificate 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-titl text-center">
                  <h4 className="title mb-4">Our Notable Users</h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3  text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/b-sides-maharashtra-logo.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3  text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/anti-cyber-crime-society-logo.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3  text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/the-cyber-agents-logo.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>

              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/ciaconference.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/cubenama.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/cubeplus.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/cyberworld.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/daiict.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/gdgu.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/IIIT-Allahabad.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/summerset-logo.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
              <div className="col-md-3 text-center py-4">
                <img
                  src="../../assets/img/certifidoc-users/we-are-plymouths.png"
                  className="avatar avatar-ex-md"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section class="section bg-light border-bottom d-table w-100">
          <div class="container">
            <div class="container mt-50 mt-60">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="row mr-lg-4" id="counter">
                    <div class="col-md-6 col-12">
                      <div
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        class="card explore-feature border-0 rounded text-center bg-white shadow"
                      >
                        <div class="card-body">
                          <div class="icon rounded-circle shadow-lg d-inline-block">
                            <CertificateIcon size="36" />
                          </div>
                          <h5 class="mt-3 title">
                            100+ Certificates in 1 Minute
                          </h5>
                          <p class="text-muted mb-0">
                            Generate 100+ certificates for a single event
                            without wasting time
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4 pt-2">
                      <div
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        class="card explore-feature border-0 rounded text-center bg-white shadow"
                      >
                        <div class="card-body">
                          <div class="icon rounded-circle shadow-lg d-inline-block">
                            <QrcodeScanIcon size="36" />
                          </div>
                          <h5 class="mt-3 title">QR Verification</h5>
                          <p class="text-muted mb-0">
                            QR code placed on each certificate for quick
                            verification of certificate holder and credentials
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4 pt-2">
                      <div
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        class="card explore-feature border-0 rounded text-center bg-white shadow"
                      >
                        <div class="card-body">
                          <div class="icon rounded-circle shadow-lg d-inline-block">
                            <CalendarEditIcon size="36" />
                          </div>
                          <h5 class="mt-3 title">Event Maker</h5>
                          <p class="text-muted mb-0">
                            Create multiple events for generating different
                            certificates
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 mt-4 pt-2">
                      <div
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        class="card explore-feature border-0 rounded text-center bg-white shadow"
                      >
                        <div class="card-body">
                          <div class="icon rounded-circle shadow-lg d-inline-block">
                            <ShareIcon size="36" />
                          </div>
                          <h5 class="mt-3 title">Sharing</h5>
                          <p class="text-muted mb-0">
                            Certificates can be shared on Social Media by
                            Recipients + Add to Linkedin Certificates
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-sm-0">
                  <div class="section-title ml-lg-4">
                    <h4 class="title mb-3">Key Features</h4>
                    <p class="text-muted">
                      Certifidoc provides the best features to Generate, Manage
                      and Publish certificates.
                    </p>
                    <div class="mt-4">
                      <h4 class="font-alt left">Free to use</h4>

                      <h4 class="font-alt left">
                        <i class="uil uil-water-drop-slash"></i>
                        No Certifidoc Watermark
                        <sup>
                          <a
                            data-original-title="Wilyer Apps are tested to run for extensive periods of times if the Display Screen is running"
                            data-toggle="tooltip"
                            data-placement="bottom"
                          >
                            <span class="fa fa-question-circle"></span>
                          </a>
                        </sup>
                      </h4>
                      <h4 class="font-alt left">
                        <i class="uil uil-linkedin"></i>
                        Certificate Sharing
                        <sup>
                          <a
                            data-original-title="In Signage App if network connection is lost App will keep playing content which was previously loaded "
                            data-toggle="tooltip"
                            data-placement="bottom"
                          >
                            <span class="fa fa-question-circle"></span>
                          </a>
                        </sup>
                      </h4>

                      <h4 class="font-alt left">
                        <i class="uil uil-image-plus"></i>
                        Upload your Custom Design
                      </h4>

                      <h4 class="font-alt left">Minimal Certificate Editor</h4>

                      <h4 class="font-alt left">
                        <i class="uil uil-file-upload-alt"></i>
                        Upload Excel Sheet
                        <sup>
                          <a
                            data-original-title="Next Image and Video will keep looping as uploaded by you"
                            data-toggle="tooltip"
                            data-placement="bottom"
                          >
                            <span class="fa fa-question-circle"></span>
                          </a>
                        </sup>
                      </h4>

                      <h4 class="font-alt left">
                        <i class="uil uil-file-export"></i>
                        Export Recipients List
                        <sup>
                          <a
                            data-original-title="Next Image and Video will keep looping as uploaded by you"
                            data-toggle="tooltip"
                            data-placement="bottom"
                          >
                            <span class="fa fa-question-circle"></span>
                          </a>
                        </sup>
                      </h4>
                      <a
                        href={Server.Client + "/signup"}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-outline-primary rounded-pill mt-3"
                      >
                        Sign up for Free <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section border-bottom d-table w-100">
          <div class="container">
            <div class="row align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                      <h4 class="title mb-4">
                        Who can use Certifidoc - Digital Certificate Management
                        System
                      </h4>
                      <p class="text-muted para-desc mb-0 mx-auto">
                        Certificates of Participation, Achievement, Performance,
                        Qualification, Internship, Completion, Promotion,
                        Winning, and many more, can be published by following
                        various entities as follows:
                      </p>
                    </div>
                  </div>
                </div>

                <br />

                <div class="row">
                  <div class="col-lg-3 col-md-6 col-12">
                    <div class="media align-items-center features">
                      <div class="icons m-0 rounded h2 text-blue text-center px-3">
                        <Briefcase size="30" />
                      </div>
                      <div class="content ml-3">
                        <h5 class="mb-1">
                          <a href="javascript:void(0)" class="text-dark">
                            Buisness
                          </a>
                        </h5>
                        <p class="text-muted mb-0">
                          Startups, Corporates, Enterprises
                          <span class="d-md-none">etc..</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="media align-items-center features">
                      <div class="icons m-0 rounded h2 text-blue text-center px-3">
                        <HospitalBuildingIcon size="30" />
                      </div>
                      <div class="content ml-3">
                        <h5 class="mb-1">
                          <a href="javascript:void(0)" class="text-dark">
                            Education/Health
                          </a>
                        </h5>
                        <p class="text-muted mb-0">
                          Colleges, Universities, Institutes
                          <span class="d-md-none">, Universities etc..</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                    <div class="media align-items-center features">
                      <div class="icons m-0 rounded h2 text-blue text-center px-3">
                        <StoreIcon size="30" />
                      </div>
                      <div class="content ml-3">
                        <h5 class="mb-1">
                          <a href="javascript:void(0)" class="text-dark">
                            Event Organizers
                          </a>
                        </h5>
                        <p class="text-muted mb-0">
                          Workshops, Meet-Ups, Competitions
                          <span class="d-md-none">
                            , Webinars, Conferences etc
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                    <div class="media align-items-center features">
                      <div class="icons m-0 rounded h2 text-blue text-center px-3">
                        <Globe size="30" />
                      </div>
                      <div class="content ml-3">
                        <h5 class="mb-1">
                          <a href="javascript:void(0)" class="text-dark">
                            Organizations
                          </a>
                        </h5>
                        <p class="text-muted mb-0">
                          Welfare society, NGOs, Social workers
                          <span class="d-md-none">etc...</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section bg-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                  <h6 class="text-blue">
                    Create Digital QR Based Certificates with quick and easy
                    steps.
                  </h6>
                  <h4 class="title mb-2">How it works</h4>
                  <p class="text-muted para-desc mx-auto mb-0">
                    Signup and follow the following steps to create and publish
                    online Certificates
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                data-aos="flip-right"
                data-aos-duration="1000"
                class="col-md-4 mt-4 pt-2"
              >
                <div
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  class="card features work-process bg-transparent process-arrow border-0 text-center"
                >
                  <div class="icons rounded h1 text-center text-blue px-3">
                    <Award size="30" />
                  </div>

                  <div class="card-body">
                    <h4 class="title text-dark">
                      Create New Event and Upload Certificate Design
                    </h4>
                    <p class="text-muted mb-0">
                      Event Creation is a step where you will be setting up
                      details about the Event for which you wish to create QR
                      based Digital Certificates.
                    </p>
                  </div>
                </div>
              </div>

              <div
                data-aos="flip-right"
                data-aos-duration="1000"
                class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2"
              >
                <div
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  class="card features work-process bg-transparent process-arrow border-0 text-center"
                >
                  <div class="icons rounded h1 text-center text-info px-3">
                    <Users size="30" />
                  </div>

                  <div class="card-body">
                    <h4 class="title text-dark">Upload Recipient Details</h4>
                    <p class="text-muted mb-0">
                      Upload Your Recipients' Details as Excel sheet and let
                      CertifiDoc bake it for you.
                    </p>
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-right"
                data-aos-duration="1000"
                class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2"
              >
                <div
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  class="card features work-process bg-transparent d-none-arrow border-0 text-center"
                >
                  <div class="icons rounded h1 text-center text-success px-3">
                    <CheckCircle size="30" />
                  </div>

                  <div class="card-body">
                    <h4 class="title text-dark">Publish and Notify</h4>
                    <p class="text-muted mb-0">
                      Switch a button to Publish to select eligible Recipients
                      and host them for Sharing and Verification.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="container">
            <div class="rounded bg-primary p-lg-5 p-4">
              <div class="row align-items-end">
                <div class="col-md-8">
                  <div class="section-title text-md-left text-center">
                    <h4 class="title mb-3 text-white title-dark">
                      Start using Certifidoc for Free
                    </h4>
                    <p class="text-white-50 mb-0">
                      Certifidoc can help you create professional Certificate
                      which can be shared by recipients and make your brand/work
                    </p>
                  </div>
                </div>

                <div class="col-md-4 mt-4 mt-sm-0">
                  <div class="text-md-right text-center">
                    <a
                      href={Server.Client + "/signup"}
                      target="_blank"
                      rel="noopener noreferrer"
                      class="btn btn-light rounded-pill"
                    >
                      Get Started for Free
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container mt-3 mt-100">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-blue">
                    <Briefcase size="36" />
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Our Vision</h5>
                    <p class="para text-muted mb-0">
                      Our Vision with Certifidoc is to simplify the process of
                      creating and publishing certificates with Digital
                      Authenticity
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-blue">
                    <RocketIcon size="36" />
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Why is Certifidoc created</h5>
                    <p class="para text-muted mb-0">
                      Certificate publishing and sharing has always been complex
                      duty for Organizers so Certifidoc is a speedy and reliable
                      Certificate Management System.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                  <span class="h1 icon2 text-blue">
                    <CrosshairsGpsIcon size="36" />
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Our Goal</h5>
                    <p class="para text-muted mb-0">
                      We aim to help startups, businesses and NGOs to recognize
                      their valued people with certificates for their
                      contributions and attendence on various events.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Qstn mt="100" />
        </section>
        <Footer />
        <a href="#" class="btn btn-icon btn-soft-primary back-to-top">
          <ArrowUp className="icons" />
        </a>
      </main>
    );
  }
}
