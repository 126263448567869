import { PureComponent } from "react";

const hostname = window.location.href;
// const hostname = "kidskriya";

export default class Server extends PureComponent {
  static Logo = hostname.includes("littlecuberssolution")
    ? "littlecuberssolution.png"
    : hostname.includes("kidskriya")
    ? "littlecuberssolution.png"
    : hostname.includes("juniorcubeassociation")
    ? "juniorcubeassociation.png"
    : "certifidoc_logo.png";

  static Server = hostname.includes("localhost")
    ? "http://localhost:8000"
    : hostname.includes("littlecuberssolution")
    ? "https://cert-api.littlecuberssolution.com"
    : hostname.includes("kidskriya")
    ? "https://cert-api.kidskriya.com"
    : hostname.includes("juniorcubeassociation")
    ? "https://cert-api.juniorcubeassociation.com"
    : "https://api.certifidoc.com";

  static Client = hostname.includes("localhost")
    ? "http://localhost:3000"
    : hostname.includes("littlecuberssolution")
    ? "https://cert-dashboard.littlecuberssolution.com"
    : hostname.includes("kidskriya")
    ? "https://cert-dashboard.kidskriya.com"
    : hostname.includes("juniorcubeassociation")
    ? "https://dashboard.juniorcubeassociation.com"
    : "https://dashboard.certifidoc.com";

  static Share = hostname.includes("localhost")
    ? "http://localhost:3001"
    : hostname.includes("littlecuberssolution")
    ? "https://cert-share.littlecuberssolution.com"
    : hostname.includes("kidskriya")
    ? "https://cert-share.kidskriya.com"
    : "https://share.certifidoc.com";

  static Cert = hostname.includes("localhost")
    ? "http://localhost:3001/"
    : hostname.includes("littlecuberssolution")
    ? "https://cert.littlecuberssolution.com/"
    : hostname.includes("kidskriya")
    ? "https://cert.kidskriya.com/"
    : hostname.includes("juniorcubeassociation")
    ? "https://cert.juniorcubeassociation.com/"
    : "https://cert.certifidoc.com/";

  static Front = hostname.includes("localhost")
    ? "http://localhost:3000"
    : hostname.includes("littlecuberssolution")
    ? "https://certificate.littlecuberssolution.com"
    : hostname.includes("kidskriya")
    ? "https://certificate.kidskriya.com"
    : hostname.includes("juniorcubeassociation")
    ? "https://certificate.juniorcubeassociation.com"
    : "https://certifidoc.com";

  // Local
  // static Server = "http://localhost:8000";
  // static Share  = "http://localhost:8000";
  // static Client = "http://localhost:3000";
  // static Cert   = "http://localhost:3000/";
  // static Front    = "http://localhost:3000";

  // recaptcha keys
  static SITE_KEY = "6Lf92dIZAAAAAGkN-KKcyJsgxE_3nK67qJ3w6ncq";
  // static SECRET_KYE = "6Lf92dIZAAAAAHLRbHw8lcSLVzC5F9mRW0J30ErH";
}
