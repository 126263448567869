import React, { PureComponent } from "react";
import {
  Linkedin,
  Award,
  Link,
  GitMerge,
  FileText,
  MapPin,
  DownloadCloud,
  UserCheck,
  Settings,
} from "react-feather";
import "../css/style.css";
import "../css/custom.css";
import "../css/bootstrap.min.css";
import Header from "../comp/header";
import Footer from "../comp/footer";
import Server from "../comp/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Not_Found from "./not_found";
import $ from "jquery";

export default class FindCert extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      page_not_found: false,
      loaded: false,
      certs: [],
    };
  }

  componentDidMount() {
    // this.getEventDetails();

    // Header Sticky
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".sticky").addClass("nav-sticky");
      } else {
        $(".sticky").removeClass("nav-sticky");
      }
    });

    // Nav Bar toggle
    $(".navbar-toggle").on("click", function (event) {
      $(this).toggleClass("open");
      $("#navigation").slideToggle(400);
    });

    $(".navigation-menu>li").slice(-1).addClass("last-elements");

    $(".menu-arrow,.submenu-arrow").on("click", function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this)
          .parent("li")
          .toggleClass("open")
          .find(".submenu:first")
          .toggleClass("open");
      }
    });

    $(".navigation-menu a").each(function () {
      if (this.href == window.location.href) {
        $(this).parent().addClass("active");
        $(this).parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("active");
      }
    });
  }

  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  searchCertificate = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("email", this.state.email);
    axios
      .post(Server.Server + "/credential/find-credentials", params)
      .then((rsp) => {
        if (rsp.data.length === 0)
          this.notify("No Certificate found with this email", "error");
        this.setState({
          certs: rsp.data,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.notify("No Certificate found with this email", "error");
        }
      });
  };
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };

  render() {
    const { certs } = this.state;
    return (
      <main className="main-container">
        <Header />
        <section
          className="bg-home1 bg-light img-one-page align-items-center border-bottom d-table w-100"
          id="home"
        >
          <div className="container">
            <div className="container">
              <img
                src="../../assets/img/search3.svg"
                className="img-left-side d-none d-md-block float-left img-fluid mt-100"
                width="30%"
              />
              <div className="row justify-content-center">
                <div className="col-md-12 text-center mt-0 pt-0">
                  <div className="title-heading margin-top-100">
                    <h1 className="heading mb-3">
                      Find your Certificates
                      <span className="text-blue"> using Email</span>
                    </h1>
                    <p className="para-desc mx-auto text-muted">
                      Use the Email that you registered.
                    </p>
                    <div className="justify-content-center mt-0 pt-0">
                      <form
                        className="text-center"
                        onSubmit={this.searchCertificate}
                      >
                        <div className="row form-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="mx-auto form-control text-center form-shadow bg-white rounded-pill col-lg-6 col-xs-12"
                            required
                            placeholder="Email Address"
                            onChange={this.onchange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary form-rounded-lg"
                        >
                          Find My Certificates
                        </button>
                      </form>
                    </div>
                    <div className="mt-5">
                      <br />
                    </div>
                    {certs.length > 0 ? (
                      <div class="card rounded-lg">
                        <div className="card-header">
                          <h5 className="card-title mb-0">
                            Your Certificates{" "}
                            <FileText size="20" />
                          </h5>
                        </div>
                        <div className="card-">
                          <div className="table-responsive">
                            <table class="table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Event Name</th>
                                  <th scope="col">Event Org</th>
                                  <th scope="col">Event Type</th>
                                  <th scope="col">Organised</th>
                                  <th scope="col">View</th>
                                </tr>
                              </thead>
                              <tbody>
                                {certs.map((cert, index) => (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{cert.name}</td>
                                    <td>{cert.email}</td>
                                    <td>{cert.event_name}</td>
                                    <td>{cert.event_org}</td>
                                    <td>{cert.event_type}</td>
                                    <td>
                                      {new Date(
                                        cert.event_created
                                      ).toDateString()}
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={
                                          Server.Cert +
                                          "certificate/" +
                                          cert.cred_id
                                        }
                                        className="btn btn-primary btn-sm"
                                      >
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-5" />
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
          </div>
          {/*end container*/}
        </section>
        <section className="section">
          <div className="container mt-10 mt-60">
            <h1 className="title mb-3 text-center text-success">
              Features of Your Certificate
              <span />
            </h1>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <Linkedin color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Add to Linked Certificates</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <Award color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">QR Code Verification</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <GitMerge color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Certificates Linked to Email</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <Link color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Easy Link Sharing</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <FileText color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Export as PDF</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <MapPin color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Available everywhere</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <DownloadCloud color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Download Certificate</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <UserCheck color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Smooth Verification</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-4 col-md-6 mb-4 pb-2">
                <div className="media key-feature align-items-center p-3 rounded shadow">
                  <div className="icon text-center rounded-circle mr-3">
                    <Settings color="blue" size="20" />
                  </div>
                  <div className="media-body">
                    <h4 className="title mb-0">Request Changes to Name</h4>
                  </div>
                </div>
              </div>
              {/*end col*/}
              {/* <div class="col-12 text-center">
                    <a href="javascript:void(0)" class="btn btn-primary">Help <i class="mdi mdi-arrow-right"></i></a>
                </div> */}
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
        {Server.Server.includes("littlecuberssolution") ||
        Server.Server.includes("kidskriya") ? (
          ""
        ) : (
          <Footer />
        )}

        <ToastContainer />
      </main>
    );
  }
}
