import React, { PureComponent } from 'react'
import Header from '../comp/header'
import Footer from '../comp/footer'
import Qstn from '../comp/qstn'
import '../css/style.css'
import '../css/custom.css'
import '../css/preloader.css'
import '../css/bootstrap.min.css'
import $ from "jquery";



export default class faq extends PureComponent {

  componentDidMount(){
    //Sticky header
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
          $(".sticky").addClass("nav-sticky");
      } else {
          $(".sticky").removeClass("nav-sticky");
      }
    });
    //On scroll down show back to top
    $(window).scroll(function(){
      if ($(this).scrollTop() > 100) {
          $('.back-to-top').fadeIn();
      } else {
          $('.back-to-top').fadeOut();
      }
    }); 
    $('.back-to-top').click(function(){
      $("html, body").animate({ scrollTop: 0 }, 3000);
      return false;
    }); 

    // started and question animation
    $('#started').on('click', function(event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $("#general").offset().top - 75
      }, 2000);
    });
  $('#question').on('click', function(event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $("#support").offset().top - 75
    }, 2000);
  });

  // Nav Bar toggle 
  $('.navbar-toggle').on('click', function (event) {
    $(this).toggleClass('open');
    $('#navigation').slideToggle(400);
  });

  $('.navigation-menu>li').slice(-1).addClass('last-elements');

  $('.menu-arrow,.submenu-arrow').on('click', function (e) {
      if ($(window).width() < 992) {
          e.preventDefault();
          $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
      }
  });

  $(".navigation-menu a").each(function () {
      if (this.href == window.location.href) {
          $(this).parent().addClass("active"); 
          $(this).parent().parent().parent().addClass("active"); 
          $(this).parent().parent().parent().parent().parent().addClass("active"); 
      }
  });
    
  }
    render() {
        return (
            <main className="main-container">
                <Header/>
                <section className="bg-half  question-bg question-right bg-light d-table w-100">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                        <div className="page-next-level">
                            <div className="alert alert-light alert-pills form-shadow  explore-feature" role="alert">
                            <h2 className="heading mb-0">Frequently Asked Questions
                            </h2>
                            </div>
                        </div>
                        </div>
                        {/*end col*/}
                    </div>
                    {/*end row*/}
                    </div>
                {/*end container*/}
                </section>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-5 col-12 d-none d-md-block">
              <div className="rounded shadow p-4 sticky-bar">
                <ul className="list-unstyled mb-0">
                  <li className><a href="#general" id="started" className="mouse-down h6 text-dark">Getting Started</a>
                  </li>
                  <li className="mt-4"><a href="#support" id="question" className="mouse-down h6 text-dark">General Questions</a>
                  </li>
                </ul>
              </div>
            </div>
            {/*end col*/}
            <div className="col-lg-8 col-md-7 col-12">
              <div className="section-title" id="general">
                <h4>Getting Started</h4>
              </div>
              <div className="faq-content mt-4 pt-2">
                <div className="accordion" id="accordionExampleone">
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse0" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse0">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading0">
                        <h6 className="title mb-0"> What is CertifiDoc? </h6>
                      </div>
                    </a>
                    
                    <div id="collapse0" className="collapse" aria-labelledby="heading0" data-parent="#accordionExampleone">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Certifidoc is a Digital Certificate Maker and Event Management system for
                          (Business, Events, Schools, Colleges, Organizations) built to manage events
                          and publish certificates of Participation ,Appreciation ,Achievement
                          ,Internship etc.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse1" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse1">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading1">
                        <h6 className="title mb-0"> How to sign up for Certifidoc? </h6>
                      </div>
                    </a>
                    <div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#accordionExampleone">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          You can sign up using your email.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse2" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse2">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading2">
                        <h6 className="title mb-0"> How much does Certifidoc cost? </h6>
                      </div>
                    </a>
                    <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordionExampleone">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Currently Certifidoc free to use.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse3" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse3">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading3">
                        <h6 className="title mb-0"> Who can use Certifidoc? </h6>
                      </div>
                    </a>
                    <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordionExampleone">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Certifidoc can be used by anyone who wants to make certificates like:-
                        </p><ul>
                          <li>BUSINESS - Corporate companies , Startups , Enterprises etc.</li>
                          <li>EDUCATIONAL INSTITUTES - Schools ,Colleges ,Universities, Coaching
                            etc.</li>
                          <li>EVENT ORGANIZERS - Workshops, Webinars, Conferences, Meet-Ups etc.
                          </li>
                          <li>ACADEMIES/GYMNASIUMS - Gyms,Academic coach,Health academy Etc.</li>
                          <li>NON-PROFITABLE ORGANIZATIONS - Welfare society ,NGO's ,Social
                            workers Etc.</li>
                        </ul>
                        <p />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-title mt-5" id="support">
                <h4>General Questions</h4>
              </div>
              <div className="faq-content mt-4 pt-3">
                <div className="accordion" id="accordionExampletwo">
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse5" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse5">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading5">
                        <h6 className="title mb-0"> Can Recipients share their certificates? </h6>
                      </div>
                    </a>
                    <div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          YES! They can share certificates online on various social media
                          sites(Linkedin , Facebook, Twitter, Whatsapp etc.) using Certfidoc with a
                          few clicks.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse6" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse6">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading6">
                        <h6 className="title mb-0"> Can I make multiple events?
                        </h6>
                      </div>
                    </a>
                    <div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          YES! Certifidoc allows you to create multiple events and publish
                          certificates.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse7" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse7">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading7">
                        <h6 className="title mb-0"> How many certificates entries can be published in one
                          time?
                        </h6>
                      </div>
                    </a>
                    <div id="collapse7" className="collapse" aria-labelledby="heading7" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          100+ at a time if you are using a Excel sheet to upload the entries.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse8" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse8">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading8">
                        <h6 className="title mb-0"> What types of certificates can be published? </h6>
                      </div>
                    </a>
                    <div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          With certifidoc you can publish different types of certificates for
                          internship, webinar, participation, course completion, job letter,
                          internship letter, reference letter,ranking certificate etc.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse9" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse9">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading9">
                        <h6 className="title mb-0"> Can I upload my custom designed template? </h6>
                      </div>
                    </a>
                    <div id="collapse9" className="collapse" aria-labelledby="heading9" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Yes ! You can upload custom template design by uploading the image of the
                          design in required format (jpeg,png,jpg).
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse10" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse10">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading10">
                        <h6 className="title mb-0"> Can the design of the certificate be updated later on?
                        </h6>
                      </div>
                    </a>
                    <div id="collapse10" className="collapse" aria-labelledby="heading10" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Yes ! The design of the certificate can be updated later on by visiting your
                          event column where you will find an edit button for making changes in your
                          design.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                  <div className="card border-0 rounded mb-2">
                    <a data-toggle="collapse" data-target="#collapse11" className="faq position-relative collapsed pointer" aria-expanded="false" aria-controls="collapse11">
                      <div className="card-header border-0 bg-light p-3 pr-5" id="heading11">
                        <h6 className="title mb-0"> Can I download the data of the issued certificates?
                        </h6>
                      </div>
                    </a>
                    <div id="collapse11" className="collapse" aria-labelledby="heading11" data-parent="#accordionExampletwo">
                      <div className="card-body px-2 py-4">
                        <p className="text-muted mb-0 faq-ans">
                          Yes ! You can export or download the data entries that are uploaded by using
                          the Export data option in the Event detail section.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Next faq*/}
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
       <Qstn mt="100"/>
        {/*end container*/}
      </section>
      <Footer/>
                
            </main>
        )
    }
}
