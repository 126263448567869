import React, { PureComponent } from 'react'
import { Home,Facebook,Mail ,Twitter, Linkedin, Instagram} from "react-feather";
import WhatsappIcon from 'mdi-react/WhatsappIcon';

export default class Qstn extends PureComponent {
    render() {
        return (
                <div className={"container mt-"+this.props.mt}>
                    <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                        <h4 className="title mb-4">Have Any Questions?</h4>
                        <p className="text-muted-header para-desc mx-auto mb-0">We would be happy to help you out with answers to any
                            questions. Feel Free to Contact us.</p>
                        <ul className="mb-0 p-0">
                            <li className="list-inline-item mx-2 mt-3">
                                <a target="_blank" href="https://www.linkedin.com/company/wilyer-tech" className="btn btn-icon btn-pills btn-sm btn-secondary">
                                    <Linkedin size="16"/>
                                </a>
                            </li>
                            <li className="list-inline-item mx-2 mt-3">
                                <a target="_blank" href="https://twitter.com/wilyerofficial/" className="btn btn-icon btn-pills btn-sm btn-info">
                                <Twitter size="16"/>
                                </a>
                            </li>
                            <li className="list-inline-item mx-2 mt-3">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=918595119451" className="btn btn-icon btn-pills btn-sm btn-success">
                                    <WhatsappIcon size="16"/>
                                </a>
                            </li>
                            <li className="list-inline-item mx-2 mt-3">
                                <a target="_blank" href="https://instagram.com/wilyerofficial/" className="btn btn-icon btn-pills btn-sm btn-danger" >
                                <Instagram size="16"/>
                                </a>
                            </li>
                            <li className="list-inline-item mx-2 mt-3">
                                <a target="_blank" href="https://facebook.com/wilyerofficial/" className="btn btn-icon btn-pills btn-sm btn-primary">
                                    <Facebook size="16"/>
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    {/*end col*/}
                    </div>
                    {/*end row*/}
                    <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="media align-items-center shadow rounded p-4 features">
                        <div className="icons m-0 rounded h2 text-primary text-center px-3">
                            <Mail color="#2046F5"/>
                        </div>
                        <div className="content ml-4">
                            <h5 className="mb-1"><a href="javascript:void(0)" className="text-dark">Drop us an Email us</a></h5>
                            <p className="text-muted-header mb-0">You can drop us an email regarding your questions.</p>
                            <div className="mt-2">
                                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=care@wilyer.com" target="_blank" className=" btn btn-sm btn-soft-primary ">Send on Gmail</a>
                                <a href="mailto: care@wilyer.com" target="_blank" className="btn btn-sm btn-soft-primary ml-1 mt-md-0">Other Email</a>
                            

                            
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="media align-items-center shadow rounded p-4 features">
                        <div className="icons m-0 rounded h2 text-primary text-center px-3">
                            <WhatsappIcon color="#2046F5"/>
                        </div>
                        <div className="content ml-4">
                            <h5 className="mb-1">Drop your Question on</h5>
                            <p className="text-muted-header mb-0">Our Team will get in touch you quickly on WhatsApp</p>
                            <div className="mt-2">
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=918595119451" className="btn btn-sm btn-soft-primary">Start WhatsApp Chat</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*end col*/}
                    </div>
                </div>
        )
    }
}
