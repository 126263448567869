import React, { Fragment, PureComponent } from "react";
import Server from "./server";

export default class Header extends PureComponent {
  render() {
    return (
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          {/* Logo container*/}
          <div>
            <a className="logo" href="/">
              <img src={"../../assets/img/" + Server.Logo} height={70} alt="" />
            </a>
          </div>
          {/*end login button*/}
          {/* End Logo container*/}
          {Server.Server.includes("certifidoc") ||
          Server.Server.includes("localhost") ? (
            <Fragment>
              <div className="menu-extras">
                <div className="menu-item">
                  {/* Mobile menu toggle*/}
                  <a className="navbar-toggle">
                    <div className="lines">
                      <span />
                      <span />
                      <span />
                    </div>
                  </a>
                  {/* End mobile menu toggle*/}
                </div>
              </div>
              {/* Show on medium big screens*/}
              <div className="buy-button d-none d-md-block">
                {/* <a rel="noreferrer" href={"/find-certificate"} target="_blank" className="btn btn-sm btn-outline-primary rounded-pill">Find Certificate</a> */}
                <a
                  target="_blank"
                  rel="noopener"
                  href={Server.Client + "/signup"}
                  className="btn btn-sm btn-outline-primary rounded-pill ml-1"
                >
                  Sign up
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  href={Server.Client}
                  className="btn btn-sm btn-outline-primary rounded-pill ml-1"
                >
                  Sign in
                </a>
              </div>
              <div id="navigation">
                {/* Navigation Menu*/}
                <ul className="navigation-menu nav-dark">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/faq">FAQs</a>
                  </li>
                  <li>
                    <a href="/find-certificates">Find Certificate</a>
                  </li>
                  {/* Only show in small screens*/}
                  <div className="buy-button d-block d-md-none">
                    {/* <a rel="noreferrer" href="find.html" target="_blank" className="btn btn-sm btn-outline-primary rounded-pill">Find Certificate</a> */}
                    <a
                      rel="noreferrer"
                      href="https://dashboard.certifidoc.com/signup"
                      target="_blank"
                      className="btn btn-sm btn-outline-primary rounded-pill"
                    >
                      Sign up
                    </a>
                    <a
                      rel="noreferrer"
                      href="https://dashboard.certifidoc.com/"
                      target="_blank"
                      className="btn btn-sm btn-outline-primary rounded-pill"
                    >
                      Sign in
                    </a>
                  </div>
                </ul>
                {/*end navigation menu*/}
                {/*end login button*/}
              </div>
            </Fragment>
          ) : (
            ""
          )}
          {/*end navigation*/}
          {/*end navigation*/}
        </div>
        {/*end container*/}
      </header>
    );
  }
}
