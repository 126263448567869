import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Not_Found from "./components/source/not_found";
import FindCert from "./components/source/find_cert";
import FindAllCert from "./components/source/find_all_cert";
import Home from "./components/source/home";
import Faq from "./components/source/faq";
import Server from "./components/comp/server";

function App() {
  return (
    <BrowserRouter>
      {Server.Server.includes("littlecuberssolution") ||
      Server.Server.includes("juniorcubeassociation") ? (
        <Switch>
          <Route path="/find-certificate/:event_id" component={FindCert} />
          <Route path="/find-certificates" component={FindAllCert} />
          <Route path="/certificate-not-found" component={Not_Found} />
          <Route path="/" component={FindAllCert} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/find-certificate/:event_id" component={FindCert} />
          <Route path="/find-certificates" component={FindAllCert} />
          <Route path="/certificate-not-found" component={Not_Found} />
          <Route path="/faq" component={Faq} />
          <Route path="/" component={Home} />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default App;
