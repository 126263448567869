import React, { PureComponent } from 'react'
import { Home,Facebook,Mail ,Twitter, Linkedin, Instagram} from "react-feather";
import WhatsappIcon from 'mdi-react/WhatsappIcon';
export default class ooter extends PureComponent {
    render() {
        return (
          <div>
            <footer className="footer theme-g-bg">
              <section className="footer-section">
                <div className="container " id="about">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 sm-m-15px-tb">
                      <h4 className="font-alt footer-header">About Us</h4>
                      <p className="footer-text">
                        Certifidoc is crafted by Wilyer for Organizers. With
                        Certifidoc, Wilyer aims to help startups and
                        organizations manage their events smoothly using
                        shophisticated certificate maker and management system
                        to stay promising and reward participants with
                        certificates quickly and easily.
                      </p>
                      <ul className="mb-0 p-0">
                        <li className="list-inline-item mx-2 mt-3">
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/wilyer-tech"
                            className="btn btn-icon btn-pills btn-sm btn-secondary"
                          >
                            <Linkedin size="16" />
                          </a>
                        </li>
                        <li className="list-inline-item mx-2 mt-3">
                          <a
                            target="_blank"
                            href="https://twitter.com/wilyerofficial/"
                            className="btn btn-icon btn-pills btn-sm btn-info"
                          >
                            <Twitter size="16" />
                          </a>
                        </li>
                        <li className="list-inline-item mx-2 mt-3">
                          <a
                            target="_blank"
                            href="https://api.whatsapp.com/send?phone=918595119451"
                            className="btn btn-icon btn-pills btn-sm btn-success"
                          >
                            <WhatsappIcon size="16" />
                          </a>
                        </li>
                        <li className="list-inline-item mx-2 mt-3">
                          <a
                            target="_blank"
                            href="https://instagram.com/wilyerofficial/"
                            className="btn btn-icon btn-pills btn-sm btn-danger"
                          >
                            <Instagram size="16" />
                          </a>
                        </li>
                        <li className="list-inline-item mx-2 mt-3">
                          <a
                            target="_blank"
                            href="https://facebook.com/wilyerofficial/"
                            className="btn btn-icon btn-pills btn-sm btn-primary"
                          >
                            <Facebook size="16" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 sm-m-15px-tb">
                      <h4 className="font-alt footer-header">Get in touch</h4>
                      <p>Gurugram, Haryana, India</p>

                      <p>
                        <span>E-Mail:</span>
                        <a
                          href="mailto: care@wilyer.com"
                          target="_blank"
                          className="text-warning"
                        >
                          {" "}
                          care@wilyer.com
                        </a>
                      </p>
                      <p>
                        <span>Mobile:</span> +918800581956, +918595119451
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </footer>
            <footer className="footer footer-bar">
              <div className="container text-center ">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-6">
                    <div className="text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Wilyer. Made with{" "}
                        <i className="mdi mdi-heart text-danger" /> by
                        Certifidoc Team{" "}
                        <a
                          href="http://wilyer.com/"
                          rel="dofollow"
                          target="_blank"
                          className="text-reset"
                        >
                          @ Wilyer
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
              {/*end container*/}
            </footer>
          </div>
        );
    }
}
