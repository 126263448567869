import React, { PureComponent } from "react";
import {
  Instagram,
  Linkedin,
  Twitter,
  Award,
  Link,
  GitMerge,
  FileText,
  MapPin,
  DownloadCloud,
  UserCheck,
  Settings,
} from "react-feather";
import "../css/style.css";
import "../css/custom.css";
import "../css/bootstrap.min.css";
import Header from "../comp/header";
import Footer from "../comp/footer";
import Server from "../comp/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Not_Found from "./not_found";
import $ from "jquery";

export default class FindCert extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event_id: this.props.match.params.event_id,
      email: null,
      page_not_found: false,
      loaded: false,
    };
  }

  componentDidMount() {
    this.getEventDetails();

    // Header Sticky
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".sticky").addClass("nav-sticky");
      } else {
        $(".sticky").removeClass("nav-sticky");
      }
    });

    // Nav Bar toggle
    $(".navbar-toggle").on("click", function (event) {
      $(this).toggleClass("open");
      $("#navigation").slideToggle(400);
    });

    $(".navigation-menu>li").slice(-1).addClass("last-elements");

    $(".menu-arrow,.submenu-arrow").on("click", function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this)
          .parent("li")
          .toggleClass("open")
          .find(".submenu:first")
          .toggleClass("open");
      }
    });

    $(".navigation-menu a").each(function () {
      if (this.href == window.location.href) {
        $(this).parent().addClass("active");
        $(this).parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("active");
      }
    });
  }

  getEventDetails = () => {
    var evt_id = this.state.event_id;
    axios
      .get(Server.Server + "/event/public-event-details/" + evt_id)
      .then((rsp) => {
        if (!rsp.data.publish) {
          this.setState({ Not_Found: true });
        }
        this.setState({ event_name: rsp.data.public_name, loaded: true });
      })
      .catch((error) => {
        if (error.response) {
          window.location.href = "/find-certificates";
          this.setState({
            loaded: true,
          });
        }
      });
  };

  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  searchCertificate = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("email", this.state.email);
    axios
      .post(
        Server.Server + "/credential/find-credential/" + this.state.event_id,
        params
      )
      .then((rsp) => {
        window.location.href = Server.Cert + "certificate/" + rsp.data.cred_id;
      })
      .catch((error) => {
        if (error.response) {
          this.notify("No Certificate found with this email", "error");
        }
      });
  };
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };

  render() {
    return this.state.loaded ? (
      this.state.page_not_found ? (
        <Not_Found />
      ) : (
        <main className="main-container">
          <Header />
          <section
            className="bg-home1 bg-light img-one-page align-items-center border-bottom d-table w-100"
            id="home"
          >
            <div className="container">
              <div className="container">
                <img
                  src="../../assets/img/search3.svg"
                  className="img-left-side d-none d-md-block float-left img-fluid mt-100"
                  width="30%"
                />
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center mt-0 pt-0">
                    <div className="title-heading margin-top-100">
                      <h1 className="heading mb-3">
                        Find your Certificate
                        <span className="text-blue"> using Email</span>
                      </h1>
                      <h2 className="title">
                        <span className="text-blue">
                          {"For : " + this.state.event_name}
                        </span>
                      </h2>
                      <p className="para-desc mx-auto text-muted">
                        Use the Email that you registered for this event.
                      </p>
                      <div className="justify-content-center mt-0 pt-0">
                        <form
                          className="text-center"
                          onSubmit={this.searchCertificate}
                        >
                          <div className="row form-group">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="mx-auto form-control text-center form-shadow bg-white rounded-pill col-lg-6 col-xs-12"
                              required
                              placeholder="Email Address"
                              onChange={this.onchange}
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary form-rounded-lg"
                          >
                            Find My Certificate
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="mt-5" />
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
            </div>
            {/*end container*/}
          </section>
          <section className="section">
            <div className="container mt-10 mt-60">
              <h1 className="title mb-3 text-center text-success">
                Features of Your Certificate
                <span />
              </h1>
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <Linkedin color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Add to Linked Certificates</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <Award color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">QR Code Verification</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <GitMerge color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">
                        Certificates Linked to Email
                      </h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <Link color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Easy Link Sharing</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <FileText color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Export as PDF</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <MapPin color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Available everywhere</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <DownloadCloud color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Download Certificate</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <UserCheck color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Smooth Resume Verification</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-4 col-md-6 mb-4 pb-2">
                  <div className="media key-feature align-items-center p-3 rounded shadow">
                    <div className="icon text-center rounded-circle mr-3">
                      <Settings color="blue" size="20" />
                    </div>
                    <div className="media-body">
                      <h4 className="title mb-0">Request Changes to Name</h4>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                {/* <div class="col-12 text-center">
                    <a href="javascript:void(0)" class="btn btn-primary">Help <i class="mdi mdi-arrow-right"></i></a>
                </div> */}
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end container*/}
          </section>
          {Server.Server.includes("littlecuberssolution") ||
          Server.Server.includes("kidskriya") ? (
            ""
          ) : (
            <Footer />
          )}

          <ToastContainer />
        </main>
      )
    ) : (
      // <!-- Loader -->
      <div id="preloader">
        <div id="status">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
    // <!-- Loader -->
  }
}
